import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  WORKTIME_NOTIFICATION_ADMIN_TABLE_COLUMNS
} from "../../../config/table-configurations";
import {TranslationService} from "../../../services/translate.service";
import {filter, Subject, switchMap, take, takeUntil, tap} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {WebUser} from "../../../interfaces/webuser";
import {WebUserStore} from "../../../stores/webUser.store";
import {DatePipe} from "@angular/common";
import {FormBuilder} from "@angular/forms";
import {MatPaginator} from "@angular/material/paginator";
import {WorktimeNotificationService} from "../../../api/worktime-notification.service";
import {WorktimeNotification} from "../../../interfaces/worktime_notification";

@Component({
  selector: 'app-worktime-notification-admin',
  templateUrl: './worktime-notification-admin.component.html',
  styleUrls: ['./worktime-notification-admin.component.scss']
})
export class WorktimeNotificationAdminComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  destroy$ = new Subject<void>();
  webUser: WebUser;

  /********
   Table
   *********/
  tableData = new MatTableDataSource<WorktimeNotification>;
  displayedColumns: string[] = WORKTIME_NOTIFICATION_ADMIN_TABLE_COLUMNS;
  columnHeaders = this.translationService.translateStrings(this.displayedColumns)


  constructor(private fb: FormBuilder, public datePipe: DatePipe, private webUserStore: WebUserStore, private translationService: TranslationService, private worktimeNotificationService: WorktimeNotificationService) {
  }

  ngOnInit(): void {


    this.webUserStore.currentWebUser$.pipe(
      takeUntil(this.destroy$)).subscribe(webUser => {
      this.webUser = webUser;
    });

    this.readAllNotifications();

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
  }

  readAllNotifications(): void {
    this.tableData.data = [];
    this.worktimeNotificationService.readAllWorktimeNotification().pipe(take(1)).subscribe((worktimeNotifications: WorktimeNotification[]) => {
      this.tableData.data = worktimeNotifications.filter(worktimeNotificiation => !worktimeNotificiation.approved);
    });
  }

  onApprove(notification: WorktimeNotification) {
    if (confirm('Möchten Sie diese Zeitmeldung wirklich genehmigen?')) {
      this.worktimeNotificationService.updateWorktimeNotification(notification._id, {
        ...notification,
        approved: true
      }).subscribe(() => {
        this.readAllNotifications();
      });
    }

  }
}
