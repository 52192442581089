import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { WORKTIME_ADMIN_TABLE_COLUMNS} from "../../../config/table-configurations";
import {TranslationService} from "../../../services/translate.service";
import {Worktime} from "../../../interfaces/worktime";
import {filter, Subject, switchMap, take, takeUntil, tap} from "rxjs";
import {WorktimeService} from "../../../api/worktime.service";
import {MatTableDataSource} from "@angular/material/table";
import {WebUser} from "../../../interfaces/webuser";
import {WebUserStore} from "../../../stores/webUser.store";
import {DatePipe} from "@angular/common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-worktime',
  templateUrl: './worktime.component.html',
  styleUrls: ['./worktime.component.scss']
})
export class WorktimeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;


  destroy$ = new Subject<void>();
  webUser: WebUser;
  tooltipText: string = "Keine Zeiterfassung gefunden";
  earliestDate: Date | null = null;
  latestDate: Date | null = null;
  rowData: Worktime[];

  /********
   Table
   *********/
  tableData = new MatTableDataSource<Worktime>;
  displayedColumns: string[] = WORKTIME_ADMIN_TABLE_COLUMNS;
  columnHeaders = this.translationService.translateStrings(this.displayedColumns)
  dateRangeForm: FormGroup;



  constructor(private fb: FormBuilder, public datePipe: DatePipe, private webUserStore: WebUserStore,  private translationService: TranslationService, private worktimeAdminService: WorktimeService) {
    this.dateRangeForm = this.fb.group({
      startDate: [new Date(), Validators.required],
      endDate: [this.addWeekToDate(new Date()), Validators.required]
    });
  }

  ngOnInit(): void {
    this.webUserStore.currentWebUser$.pipe(
      takeUntil(this.destroy$),
      tap(webUser => {
        this.webUser = webUser;
      }),
      filter(webUser => webUser !== null),  // Add filter to check for non-null webUser
      switchMap(() => this.worktimeAdminService.getAllWorktimes().pipe(take(1)))
    ).subscribe((worktimes: Worktime[]) => {
      if (this.webUser.roleOfUser !== 'admin') {
        this.tableData.data = worktimes.filter(worktime => worktime.driverNo === this.webUser.driverno)
        this.rowData = worktimes.filter(worktime => worktime.driverNo === this.webUser.driverno);
      } else {
        this.tableData.data = worktimes;
        this.rowData = worktimes;
      }
      if (this.tableData.data.length > 0) {
        this.findEarliestAndLatestDate(worktimes);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
  }

  addWeekToDate(date: Date): Date {
    let result = new Date(date);
    result.setDate(result.getDate() + 7);
    return result;
  }

  filterTable(startDate: Date, endDate: Date): void {
    this.tableData.data = this.rowData;
    const filteredData = this.tableData.data.filter(row => {
      const day = new Date(row.day);
      return day >= startDate && day <= endDate;
    });
    this.tableData.data = filteredData;
  }

  findEarliestAndLatestDate(worktimes: Worktime[]) {
    const dates = worktimes.map(job => new Date(job.day));
    this.earliestDate = dates.reduce((earliest, current) => current < earliest ? current : earliest);
    this.latestDate = dates.reduce((latest, current) => current > latest ? current : latest);
    this.tooltipText = `Die früheste Zeiterfassung ist am ${this.datePipe.transform(this.earliestDate, 'dd.MM.yyyy')} und der letzte Auftrag am ${this.datePipe.transform(this.latestDate, 'dd.MM.yyyy')}`;
    if (this.earliestDate && this.latestDate) {
      this.dateRangeForm.patchValue({
        startDate: this.earliestDate,
        endDate: this.latestDate
      });
    }

    this.setFilter();
  }

  setFilter(): void {
    this.dateRangeForm.get('endDate').valueChanges.subscribe(val => {
      if (val) {
        this.filterTable(this.dateRangeForm.value.startDate, val);
      }
    });
  }

}
