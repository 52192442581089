<ng-container>
  <table *ngIf="tableData?.data.length > 0; else noData" mat-table [dataSource]="tableData">
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef> {{ columnHeaders[column] }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="column === 'day'; else defaultCell">
          {{ this.datePipe.transform(element[column], 'dd.MM.yyyy') }}
        </ng-container>
        <ng-template #defaultCell>
          <ng-container *ngIf="column === 'approved'; else defaultContent">
            <button mat-raised-button color="primary" (click)="onApprove(element)">Genehmigen</button>
          </ng-container>
          <ng-template #defaultContent>
            {{ element[column] }}
          </ng-template>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
  </table>

  <ng-container *ngIf="tableData?.data.length > 0">
    <mat-paginator [length]="this.tableData.data.length" [pageSizeOptions]="[10, 50, 100]" aria-label="Select page">
    </mat-paginator>
  </ng-container>
</ng-container>

<ng-template #noData>
  <div>Leider liegen keine Zeitmeldungen vor</div>
</ng-template>
