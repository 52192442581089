import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WebusersService {
  constructor(private httpClient: HttpClient) {
  }

  getAllWebUser(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/users`, {headers: {token: this.getUserInformationFromStorage('token')}});
  }

  getWebUserInformationFromLoggedUser() {
    return this.httpClient.get(`${environment.apiUrl}/user?id=${this.getUserInformationFromStorage('uid')}`, {headers: {token: this.getUserInformationFromStorage('token')}});
  }

  updateWebUserInformation(updateValue: any) {
    return this.httpClient.put(`${environment.apiUrl}/user?id=${this.getUserInformationFromStorage('uid')}`, updateValue, {headers: {token: this.getUserInformationFromStorage('token')}});
  }

  getUserInformationFromStorage(item: string): string {
    if (localStorage.getItem('isLoggedIn') === 'true') {
      return localStorage.getItem(item);
    } else {
      return sessionStorage.getItem(item)
    }
  }

  getWebUserInformation(id: string): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/user?id=${id}`, {headers: {token: this.getUserInformationFromStorage('token')}});
  }

  updateRoleOfUser(id: string, roleOfUser: string): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/user?id=${id}`, {roleOfUser: roleOfUser}, {headers: {token: this.getUserInformationFromStorage('token')}});
  }
}
