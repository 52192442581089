import {Component, ElementRef, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {take} from "rxjs";
import {AuthService} from "../../../api/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  passwordIsVisible = false;
  loadingSpinner = false;
  loginErrorMessage: string;

  constructor(private formBuilder: FormBuilder, private router: Router, private el: ElementRef, private authService: AuthService) {
  }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.minLength(7)],
      remember: {value: true},
    });
  }

  togglePasswordVisibility(): void {
    this.passwordIsVisible = !this.passwordIsVisible;
  }

  login() {
    const email = this.loginForm.get('email').value.toLowerCase();
    const password = this.loginForm.get('password').value;
    const passwordRemember = this.loginForm.get('remember').value;

    this.loadingSpinner = true;

    this.authService.login({email: email, password: password}).pipe(take(1)).subscribe((backendAnswer: any) => {
      if (backendAnswer['success']) {
        localStorage.clear();
        sessionStorage.clear();
        this.loadingSpinner = false;
        if (passwordRemember) {
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('token', backendAnswer['token']);
          localStorage.setItem('uid', backendAnswer['uid']);
          localStorage.setItem('isVerified', backendAnswer['verified']);
        } else {
          sessionStorage.setItem('isLoggedIn', 'true');
          sessionStorage.setItem('token', backendAnswer['token']);
          sessionStorage.setItem('uid', backendAnswer['uid']);
          sessionStorage.setItem('isVerified', backendAnswer['verified']);
        }
        this.router.navigate(['/overview-jobs']);
      } else {
        this.loadingSpinner = false;
        this.loginErrorMessage = backendAnswer.message
        this.loginForm.reset();
      }
    })
  }

  forgetPassword(): void {
    this.router.navigate(['/forget-password'])
  }

  registerNow() {
    this.router.navigate(['/register'])
  }

}
