import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService} from "../../../api/auth.service";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  newPasswordForm: FormGroup
  loadingSpinner = false;
  loginError: boolean = false;
  sendEmail: boolean = false;


  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService) {
  }

  ngOnInit() {
    this.newPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }


  newPassword() {
    const email = this.newPasswordForm.get('email').value.toLowerCase();

    this.authService.resetPassword(email).subscribe(backendAnswer => {
      console.log(backendAnswer);
    })
    this.loadingSpinner = true;
    setTimeout(() => {
      if (this.newPasswordForm.valid) {
        this.loadingSpinner = false;
        this.sendEmail = true;
      }
    }, 2000);
  }

  cancel() {
    this.router.navigate(['/login'])
  }

}
