<div class="login-container">
  <div class="header">
    <img src="../../../../assets/img/logo-taxi-hess.png">
  </div>

  <mat-error class="login-error" *ngIf="loginErrorMessage">
    <mat-icon class="exclamation-icon" inline="true">warning</mat-icon>
    {{loginErrorMessage}}  </mat-error>

  <div class="formular">
    <form [formGroup]="loginForm">
      <mat-form-field appearance="outline">
        <mat-label>E-Mail Adresse:</mat-label>
        <input matInput type="email" formControlName="email">
        <mat-error *ngIf="loginForm.get('email').invalid && loginForm.get('email').touched">Bitte geben Sie eine gültige
          E-Mail Adresse ein
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Passwort:</mat-label>
        <input [attr.type]="passwordIsVisible ? 'text' : 'password'" matInput formControlName="password">
        <mat-error *ngIf="loginForm.get('password').hasError('minlength') && loginForm.get('password').touched">
          Das Passwort muss mind. 7 Zeichen lang sein
        </mat-error>
        <mat-icon (click)="togglePasswordVisibility()"
                  matSuffix>{{ passwordIsVisible ? 'visibility_off' : 'visibility' }}</mat-icon>
      </mat-form-field>

      <mat-checkbox color="primary" formControlName="remember">Passwort speichern</mat-checkbox>

    </form>

    <button class="login-button"
            [disabled]="loginForm.invalid || loginForm.value.email.length < 1 || loginForm.value.password.length < 7"
            mat-raised-button color="primary" (click)="login()">
      Login
    </button>
    <div class="action">
      <button (click)="forgetPassword()" color="primary" mat-button>Passwort vergessen ?</button>
    </div>
  </div>

  <div class="action">
    Neu als Fahrer?
    <button (click)="registerNow()" color="primary" mat-button>Hier registrieren</button>
  </div>

</div>

<app-loading-spinner *ngIf="loadingSpinner"></app-loading-spinner>
