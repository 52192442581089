import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WebusersService } from '../../../../api/webusers.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  passwordIsVisible = false;
  registerForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private webuserService: WebusersService,
    private dialogRef: MatDialogRef<ChangePasswordComponent>
  ) {
    dialogRef.disableClose = true; // Disable closing the dialog by clicking outside
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(7)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(7)]]
    }, { validator: this.passwordMatchValidator });
  }

  togglePasswordVisibility(): void {
    this.passwordIsVisible = !this.passwordIsVisible;
  }

  passwordMatchValidator(form: FormGroup) {
    return form.get('password').value === form.get('confirmPassword').value ? null : { mismatch: true };
  }

  saveAndClose() {
    this.webuserService.updateWebUserInformation({ password: this.registerForm.value.password }).subscribe(_ => {
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
