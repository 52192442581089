import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from "./material.module";
import {OverviewDriverComponent} from './components/pages/overview-driver/overview-driver.component';
import {LoginComponent} from './core/auth/login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthGuard} from "./guard/auth_guard";
import {LoadingSpinnerComponent} from './components/global/loading-spinner/loading-spinner.component';
import {RegisterComponent} from './core/auth/register/register.component';
import {LoginGuard} from "./guard/login_guard";
import {ForgetPasswordComponent} from './core/auth/forget-password/forget-password.component';
import {DriverDetailsComponent} from "./components/pages/overview-driver/driver-details/driver-details.component";
import {ProfileRolesComponent} from './components/pages/settings/profile-roles/profile-roles.component';
import {ProfileComponent} from './components/pages/profile/profile/profile.component';
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {HttpClientModule} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {OverviewJobsComponent} from "./components/pages/overview-jobs/overview-jobs.component";
import {OverviewVehicleComponent} from "./components/pages/overview-vehicle/overview-vehicle.component";
import {WorktimeNotificationComponent} from "./components/pages/worktime-notification/worktime-notification.component";
import { WorktimeComponent } from './components/pages/worktime/worktime.component';
import {AccountingComponent} from "./components/pages/accounting/accounting.component";
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { DriverService } from './services/driver.service';
import {MatPaginatorModule} from "@angular/material/paginator";
import {ChangePasswordComponent} from "./components/pages/profile/change-password/change-password.component";
import {
  WorktimeNotificationAdminComponent
} from "./components/pages/worktime-notification-admin/worktime-notification-admin.component";



@NgModule({
  declarations: [
    AppComponent,
    OverviewDriverComponent,
    LoginComponent,
    LoadingSpinnerComponent,
    RegisterComponent,
    ForgetPasswordComponent,
    DriverDetailsComponent,
    ProfileRolesComponent,
    ProfileComponent,
    OverviewJobsComponent,
    OverviewVehicleComponent,
    WorktimeNotificationComponent,
    WorktimeComponent,
    AccountingComponent,
    ChangePasswordComponent,
    WorktimeNotificationAdminComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatCheckboxModule,
    MatButtonModule,
    MatPaginatorModule
  ],
  providers: [AuthGuard, LoginGuard, DriverService, {provide: MAT_DATE_LOCALE, useValue: 'de-DE'}, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
}
