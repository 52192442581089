<div class="headline">
  <mat-icon *ngIf="!showProfile" (click)="closeEditMode()">arrow_back</mat-icon>
  <h1>{{this.headline}}</h1>
</div>

<!-- View Mode -->
<ng-container *ngIf="showProfile && profileFormArray?.controls">
  <ng-container *ngFor="let formGroup of profileFormArray.controls; let i = index">
    <div class="card-overview-container">
      <mat-card>
        <h2>{{ this.formGroupNames[i].translation }}</h2>

        <table>
          <tr *ngFor="let controlName of getControlNames(this.formGroupNames[i].name)">
            <td>{{ getLabelForControl(controlName) }}:</td>
            <td>{{ controlName === 'birthday' ? this.datePipe.transform(formGroup.get(controlName)?.value, 'dd.MM.yyy') : formGroup.get(controlName)?.value }}</td>
          </tr>
        </table>

        <mat-divider></mat-divider>

        <mat-card-content class="content-wrapper"
                          (click)="openEditMode(this.formGroupNames[i].translation, i, formGroup)">
          <div style="display: flex">
            <mat-icon class="icon">edit</mat-icon>
            <span>{{ this.formGroupNames[i].translation }} ändern</span>
          </div>
          <div class="icon-wrapper">
            <mat-icon class="icon">arrow_forward_ios</mat-icon>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

  </ng-container>
</ng-container>

<button mat-raised-button color="primary" (click)="openPasswortChange()">Passwort ändern</button>

<ng-container *ngIf="!showProfile">
  <form [formGroup]="actualForm" class="edit-mode-container">
    <mat-card>
      <table>
        <tr *ngFor="let controlName of getControlNames(formGroupNames[actualFormNumber].name)">
          <td>
            <mat-form-field appearance="outline">
              <mat-label>{{ getLabelForControl(controlName) }}</mat-label>
              <ng-container [ngSwitch]="controlName">
                <input *ngSwitchCase="'birthday'" matInput [formControlName]="controlName" [matDatepicker]="picker">
                <input *ngSwitchDefault matInput [formControlName]="controlName">
                <mat-select *ngSwitchCase="'gender'" [formControlName]="controlName">
                  <mat-option [value]="'male'">Männlich</mat-option>
                  <mat-option [value]="'female'">Weiblich</mat-option>
                </mat-select>
              </ng-container>
              <mat-datepicker-toggle matIconSuffix [for]="picker"
                                     *ngIf="controlName === 'birthday'"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </td>
        </tr>
      </table>
    </mat-card>
  </form>
  <div class="action-button">
    <button mat-raised-button color="primary" (click)="save()" style="margin-right: 15px">Speichern</button>
    <button mat-raised-button color="primary" (click)="closeEditMode()">Abbrechen</button>
  </div>
</ng-container>



