import {Component, OnInit,} from '@angular/core';
import {Router, NavigationEnd} from "@angular/router";
import {WebUser} from "./interfaces/webuser";
import {WebUserStore} from "./stores/webUser.store";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Taxi-Hess';
  currentRoute: string;

  webUserIsAdmin = false;


  constructor(private router: Router, private webUserStore: WebUserStore) {
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
      }
    });
    this.webUserStore.currentWebUser$.subscribe((webUser: WebUser) => {
      this.webUserIsAdmin = webUser?.roleOfUser === 'admin';
    });
  }

  isActive(route: string): boolean {
    return this.currentRoute === route;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }


  isLoggedIn(): boolean {
    return (localStorage.getItem('isLoggedIn') === 'true' || sessionStorage.getItem('isLoggedIn') === 'true');
  }

  navigate(link: string) {
    this.router.navigate([`/${link}`])
  }

}

