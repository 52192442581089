import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {ProfileFrontendService} from "../services/profile-frontend.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private profileFrontendService: ProfileFrontendService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const localIsLoggedIn = localStorage.getItem('isLoggedIn');
    const sessionIsLoggedIn = sessionStorage.getItem('isLoggedIn');

    if (localIsLoggedIn === 'true' || sessionIsLoggedIn === 'true') {
      this.profileFrontendService.setProfileStore();
      return true
    } else if (localIsLoggedIn === 'false' || sessionIsLoggedIn === 'false') {
      return this.router.parseUrl('/login');
    } else {
      sessionStorage.setItem('isLoggedIn', 'false');
      return this.router.parseUrl('/login');
    }
  }
}

