import {NgModule} from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from "@angular/material/radio";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatDividerModule} from "@angular/material/divider";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from "@angular/material/core";
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';




@NgModule({
  imports: [
    MatSidenavModule, MatButtonModule, MatMenuModule,
    MatDialogModule, MatInputModule, MatSelectModule,
    MatCheckboxModule, MatIconModule, MatRadioModule,
    MatCardModule, MatFormFieldModule,
    MatToolbarModule, MatDividerModule,MatButtonToggleModule,
    MatSlideToggleModule, MatSnackBarModule, MatProgressSpinnerModule,
    MatStepperModule, MatTableModule, MatSortModule, MatDatepickerModule,
    MatNativeDateModule, MatPaginatorModule, MatTooltipModule
  ],

  exports: [
    MatSidenavModule, MatButtonModule, MatMenuModule,
    MatDialogModule, MatInputModule, MatSelectModule,
    MatCheckboxModule, MatIconModule, MatRadioModule,
    MatCardModule, MatFormFieldModule,
    MatToolbarModule, MatDividerModule,MatButtonToggleModule,
    MatSlideToggleModule, MatSnackBarModule, MatProgressSpinnerModule,
    MatStepperModule, MatTableModule, MatSortModule, MatDatepickerModule,
    MatNativeDateModule, MatPaginatorModule, MatTooltipModule
  ]
})
export class MaterialModule {
}
