<form [formGroup]="registerForm">
  <h2 mat-dialog-title>Passwort ändern</h2>
  <mat-dialog-content>
    <div class="form-group">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>neues Passwort:</mat-label>
        <input [attr.type]="passwordIsVisible ? 'text' : 'password'" matInput formControlName="password">
        <mat-error *ngIf="registerForm.get('password').hasError('minlength') && registerForm.get('password').touched">
          Das Passwort muss mind. 7 Zeichen lang sein
        </mat-error>
      </mat-form-field>
      <mat-icon (click)="togglePasswordVisibility()"
                matSuffix>{{ passwordIsVisible ? 'visibility_off' : 'visibility' }}
      </mat-icon>
    </div>
    <div class="form-group">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Passwort bestätigen:</mat-label>
        <input [attr.type]="passwordIsVisible ? 'text' : 'password'" matInput formControlName="confirmPassword">
        <mat-error
          *ngIf="registerForm.get('confirmPassword').hasError('minlength') && registerForm.get('confirmPassword').touched">
          Das Passwort muss mind. 7 Zeichen lang sein
        </mat-error>
        <mat-error *ngIf="registerForm.hasError('mismatch') && registerForm.get('confirmPassword').touched">
          Die Passwörter stimmen nicht überein
        </mat-error>
      </mat-form-field>
      <mat-icon (click)="togglePasswordVisibility()"
                matSuffix>{{ passwordIsVisible ? 'visibility_off' : 'visibility' }}
      </mat-icon>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="saveAndClose()" [disabled]="registerForm.invalid">Passwort ändern</button>
    <button mat-raised-button color="primary" (click)="cancel()">Abbrechen</button>
  </mat-dialog-actions>
</form>
