import {Injectable} from '@angular/core';
import {WebUserStore} from "../stores/webUser.store";
import {WebusersService} from "../api/webusers.service";

@Injectable({
  providedIn: 'root'
})
export class ProfileFrontendService {

  constructor(private webuserService: WebusersService, private webUserStore: WebUserStore) {
  }

  setProfileStore(): void {
    this.webuserService.getWebUserInformationFromLoggedUser(
    ).subscribe(webUser => {
      this.webUserStore.setWebUser(JSON.parse(JSON.stringify(webUser)))
    });
  }

  getUserInformationFromStorage(item: string): string {
    if (localStorage.getItem('isLoggedIn') === 'true') {
      return localStorage.getItem(item);
    } else {
      return sessionStorage.getItem(item)
    }
  }
}
