import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ProfileFrontendService} from "../services/profile-frontend.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class JobService {
  constructor(private profileService: ProfileFrontendService, private httpClient: HttpClient) {
  }

  getAllJobs(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/jobs`, {headers: {token: this.profileService.getUserInformationFromStorage('token')}});
  }

}
