import {Component, OnInit} from '@angular/core';
import {DriverData} from "../../../../interfaces/driver_data";
import {Router} from "@angular/router";
import {WebusersService} from "../../../../api/webusers.service";
import {WorktimeService} from "../../../../api/worktime.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {take} from "rxjs";

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss']
})
export class DriverDetailsComponent implements OnInit {
  driver: DriverData | undefined;
  driverId: string | undefined;
  dateRangeForm: FormGroup;


  constructor(private webuserService: WebusersService, private router: Router, private fb: FormBuilder, private worktimeService: WorktimeService) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { driverId: string };
    this.driverId = state?.driverId;
    this.dateRangeForm = this.fb.group({
      startDate: [this.minusWeekToDate(new Date()), Validators.required],
      endDate: [new Date(), Validators.required]
    });

  }

  ngOnInit(): void {
    this.webuserService.getWebUserInformation(this.driverId).subscribe((driver: DriverData) => {
      this.driver = driver;
      this.loadWorktimes(this.dateRangeForm.value.startDate.toString(), this.dateRangeForm.value.endDate.toString());
      this.setFilter();
    });
  }

  getTotalWorkTime(): number {
    if (this.driver) {
      const totalWorkTime = (this.driver.drivingTimeInMinutes + this.driver.breakTimeInMinutes + this.driver.waitingTimeInMinutes) / 60;
      return parseFloat(totalWorkTime.toFixed(2));
    }
    return 0;
  }

  loadWorktimes(startDate: string, endDate: string): void {
    this.worktimeService.getAllWorktimeOfDriver(this.driver.driverno, startDate, endDate).pipe(take(1)).subscribe((worktimes: any) => {
      this.driver.drivingTimeInMinutes = worktimes.workTime;
      this.driver.breakTimeInMinutes = worktimes.breakTime;
      this.driver.waitingTimeInMinutes = worktimes.idleTime;
    }, (error) => {
      this.driver.drivingTimeInMinutes = 0;
      this.driver.breakTimeInMinutes = 0;
      this.driver.waitingTimeInMinutes = 0;
    })
  }

  minusWeekToDate(date: Date): Date {
    let result = new Date(date);
    result.setDate(result.getDate() - 7);
    return result;
  }

  setFilter(): void {
    this.dateRangeForm.get('endDate').valueChanges.subscribe(val => {
      if (val) {
        this.loadWorktimes(new Date(this.dateRangeForm.value.startDate).toString(), new Date(val).toString());
      }
    });
  }
}
