<form [formGroup]="workTimeNotification" (ngSubmit)="onSubmit()">
  <!--  <p>
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required>
      </mat-form-field>
    </p>-->

  <p>
    <mat-form-field appearance="fill">
      <mat-label>Auftragsnummer</mat-label>
      <input matInput formControlName="jobNumber" required>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field appearance="fill">
      <mat-label>Grund für die Meldung</mat-label>
      <mat-select formControlName="reason" required>
        <mat-option *ngFor="let reason of reasons" [value]="reason">
          {{ reason }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill">
      <mat-label>Datum</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="day" required>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill">
      <mat-label>Beschreibung</mat-label>
      <textarea matInput formControlName="description" rows="4" required></textarea>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field appearance="fill">
      <mat-label>Zeit (Minuten)</mat-label>
      <input matInput formControlName="addedWorktime" type="number" required>
    </mat-form-field>
  </p>

  <p>
    <button mat-raised-button color="primary" type="submit">Absenden</button>
  </p>
</form>
