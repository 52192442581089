import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {WorktimeNotification} from "../interfaces/worktime_notification";
import {ProfileFrontendService} from "../services/profile-frontend.service";


@Injectable({
  providedIn: 'root'
})
export class WorktimeNotificationService {

  constructor(private httpClient: HttpClient, private profileFrontendService: ProfileFrontendService) {
  }

  createWorktimeNotification(workTimeNotification: WorktimeNotification): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/worktimeNotification`, workTimeNotification);
  }

  readAllWorktimeNotification(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/worktimeNotifications`, {headers: {token: this.profileFrontendService.getUserInformationFromStorage('token')}});
  }

  updateWorktimeNotification(id: string, workTimeNotification: WorktimeNotification): Observable<any> {
    return this.httpClient.patch(`${environment.apiUrl}/worktimeNotification?id=${id}`, workTimeNotification, {
      headers: { token: this.profileFrontendService.getUserInformationFromStorage('token') }
    });
  }
}
