import {Component, OnInit} from '@angular/core';
import {DriverService} from '../../../services/driver.service';
import {TranslationService} from '../../../services/translate.service';
import {ACCOUNTING_TABLE_COLUMNS} from '../../../config/table-configurations';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {forkJoin, take} from "rxjs";
import {WebUser} from "../../../interfaces/webuser";
import {WebusersService} from "../../../api/webusers.service";
import {WorktimeService} from "../../../api/worktime.service";
import {Accounting} from "../../../interfaces/accounting";
import {AdminRoutesService} from "../../../api/admin-routes.service";

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {

  drivers: any[];

  /********
   Table
   *********/
  tableData = new MatTableDataSource<Accounting>;
  displayedColumns: string[] = ACCOUNTING_TABLE_COLUMNS;
  columnsHeaders = this.translationService.translateStrings(this.displayedColumns);
  masterCheckbox: boolean = true;
  dateRangeForm: FormGroup;
  isLoading = true;


  constructor(private adminRoutesServices: AdminRoutesService, private worktimeService: WorktimeService, private webuserService: WebusersService, private driverService: DriverService, private fb: FormBuilder, private translationService: TranslationService) {
    this.dateRangeForm = this.fb.group({
      startDate: [this.minusWeekToDate(new Date()), Validators.required],
      endDate: [new Date(), Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadWorktimes(this.dateRangeForm.value.startDate.toString(), this.dateRangeForm.value.endDate.toString());
    this.dateRangeForm.get('endDate').valueChanges.subscribe(val => {
      if (val) {
        // this.filterTable(this.dateRangeForm.value.startDate, val);
      }
    });
  }


  exportSelectedData(): void {
    this.driverService.exportPayrollData(this.tableData.data.filter(data => data.select));
  }

  changeCheckbox(driver: any, checked: boolean): void {
    driver.select = checked;
    if (this.tableData.data.filter(data => data.select).length < this.tableData.data.length) {
      this.masterCheckbox = false;
    } else if (this.tableData.data.filter(data => data.select).length === this.tableData.data.length) {
      this.masterCheckbox = true;
    }
  }

  changeMaster(checked: boolean): void {
    this.masterCheckbox = checked;
    this.tableData.data.forEach(data => data.select = checked);
  }

  minusWeekToDate(date: Date): Date {
    let result = new Date(date);
    result.setDate(result.getDate() - 7);
    return result;
  }

  loadWorktimes(startDate: string, endDate: string): void {
    this.isLoading = true;
    const driversWorktimes: Accounting[] = [];

    this.adminRoutesServices.getAllWorktimeOfTimeRange(startDate, endDate).pipe(take(1)).subscribe((worktimes: any) => {
      driversWorktimes.push(worktimes);
      this.isLoading = false;
    }, (error) => {

      this.isLoading = false;

    });
    this.tableData.data = driversWorktimes;
  }
}
