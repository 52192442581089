import {AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {MatPaginator} from "@angular/material/paginator";
import {OVERVIEW_RIDES_TABLE_COLUMNS} from "../../../config/table-configurations";
import {TranslationService} from "../../../services/translate.service";
import {JobService} from "../../../api/job.service";
import {filter, Subject, switchMap, take, takeUntil, tap} from "rxjs";
import {Job} from "../../../interfaces/job";
import {WebUserStore} from "../../../stores/webUser.store";
import {WebUser} from "../../../interfaces/webuser";
import {AdminRoutesService} from "../../../api/admin-routes.service";

@Component({
  selector: 'app-overview-jobs',
  templateUrl: './overview-jobs.component.html',
  styleUrls: ['./overview-jobs.component.scss']
})
export class OverviewJobsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  destroy$ = new Subject<void>();

  webUser: WebUser;
  rowData: Job[]
  earliestDate: Date | null = null;
  latestDate: Date | null = null;
  tooltipText: string = "Kein Auftrag gefunden";

  /********
   Table
   *********/
  tableData = new MatTableDataSource<Job>;
  displayedColumns: string[] = OVERVIEW_RIDES_TABLE_COLUMNS;
  columnHeaders = this.translationService.translateStrings(this.displayedColumns);
  dateRangeForm: FormGroup;

  constructor(private adminRoutesService: AdminRoutesService, private webUserStore: WebUserStore, private jobService: JobService, public datePipe: DatePipe, private fb: FormBuilder, private translationService: TranslationService) {
    this.dateRangeForm = this.fb.group({
      startDate: [new Date(), Validators.required],
      endDate: [this.addWeekToDate(new Date()), Validators.required]
    });
  }

  ngOnInit(): void {
    this.webUserStore.currentWebUser$.pipe(
      takeUntil(this.destroy$),
      tap(webUser => {
        this.webUser = webUser;
      }),
      filter(webUser => webUser !== null),
      switchMap(() => this.jobService.getAllJobs().pipe(take(1)))
    ).subscribe((jobs: Job[]) => {
      if (this.webUser.roleOfUser === 'admin') {
        this.tableData.data = jobs;
        this.rowData = jobs;
      } else {
        this.tableData.data = jobs.filter(job => job.driverno === this.webUser.driverno);
        this.rowData = jobs.filter(job => job.driverno === this.webUser.driverno);

      }
      if (this.tableData.data.length > 0) {
        this.findEarliestAndLatestDate(jobs);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
  }


  addWeekToDate(date: Date): Date {
    let result = new Date(date);
    result.setDate(result.getDate() + 7);
    return result;
  }


  filterTable(startDate: Date, endDate: Date): void {
    this.tableData.data = this.rowData;
    const filteredData = this.tableData.data.filter(row => {
      const orderDate = new Date(row.orderdate);
      return orderDate >= startDate && orderDate <= endDate;
    });
    this.tableData.data = filteredData;
  }


  findEarliestAndLatestDate(jobs: Job[]) {
    const dates = jobs.map(job => new Date(job.orderdate));
    this.earliestDate = dates.reduce((earliest, current) => current < earliest ? current : earliest);
    this.latestDate = dates.reduce((latest, current) => current > latest ? current : latest);
    this.tooltipText = `Der früheste Auftrag ist am ${this.datePipe.transform(this.earliestDate, 'dd.MM.yyyy')} und der letzte Auftrag am ${this.datePipe.transform(this.latestDate, 'dd.MM.yyyy')}`;
    if (this.earliestDate && this.latestDate) {
      this.dateRangeForm.patchValue({
        startDate: this.earliestDate,
        endDate: this.latestDate
      });
    }

    this.setFilter();

  }

  setFilter(): void {
    this.dateRangeForm.get('endDate').valueChanges.subscribe(val => {
      if (val) {
        this.filterTable(this.dateRangeForm.value.startDate, val);
      }
    });
  }
}
