import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Profile} from "../interfaces/profile";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient) {
  }

  login(user: { email: string, password: string }) {
    return this.httpClient.post(`${environment.apiUrl}/login`, user);
  }

  registerUser(profile: Profile) {
    return this.httpClient.post(`${environment.apiUrl}/users`, profile,);
  }

  resetPassword(email: string) {
    return this.httpClient.get(`${environment.apiUrl}/pwrenew?email=${email}`);
  }

}
