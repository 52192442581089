import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private translations: { [key: string]: string } = {
    date: 'Datum',
    number: 'Nummer',
    select: 'Select',
    name1: 'Name',
    company: 'Firma',
    drivingTime: 'Fahrtzeit',
    waitingTime: 'Wartezeit',
    breakTime: 'Pausenzeit',
    payment: 'Lohnart',
    driverno: 'Fahrer-Nummer',
    street: 'Straße',
    zip: 'PLZ',
    city: 'Stadt',
    breakTimeInMinutes: 'Pausenzeit',
    waitingTimeInMinutes: 'Rufbereitschaft',
    drivingTimeInMinutes: 'Fahrzeit',
    orderdate: 'Datum',
    orderid: 'Auftragsnummer',
    ordertext: 'Auftragstext',
    drivername: 'Fahrer',
    destination: 'Ziel',
    planned_arrival_time: 'Geplante Ankunftszeit',
    name: 'Name',
    driver: 'Fahrer',
    status: 'Status',
    position: 'Position',
    event: 'Ereignis',
    lastUpdate: 'Letztes Update',
    firstname: 'Vorname',
    lastname: 'Nachname',
    licensePlate: 'Kennzeichen',
    manufacturer: 'Hersteller',
    modelName: 'Modell',
    orderstate: 'Auftragsstatus',
    reason: 'Grund',
    description: 'Beschreibung',
    day: 'Tag',
    idleTime: 'Leerlaufzeit',
    jobNo: 'Auftragsnummer',
    workTime: 'Arbeitszeit',
    driverNo: 'Fahrer-Nummer',
    roleOfUser: 'Rolle'
  }
  ;

  constructor() {
  }

  translateStrings(keys: string[]): { [key: string]: string } {
    const result: { [key: string]: string } = {};
    keys.forEach(key => {
      result[key] = this.translations[key] || '';
    });
    return result;
  }
}
