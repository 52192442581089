<!-- register form -->
<ng-container *ngIf="!sendEmailMessage">
  <div class="register-container">
    <div class="header">
      <img src="../../../../assets/img/logo-taxi-hess.png">
    </div>
    <mat-error class="register-error" *ngIf="registerErrorMessage">
      <mat-icon class="exclamation-icon" inline="true">warning</mat-icon>
      {{this.registerErrorMessage}}
    </mat-error>

    <div class="formular">


      <form [formGroup]="registerForm">

        <div class="name">
          <mat-form-field appearance="outline">
            <mat-label>Vorname:</mat-label>
            <input matInput type="text" formControlName="firstName">
            <mat-error *ngIf="registerForm.get('firstName').invalid && registerForm.get('firstName').touched">Bitte
              geben
              Sie einen Vornamen ein
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Nachname:</mat-label>
            <input matInput type="text" formControlName="lastName">
            <mat-error *ngIf="registerForm.get('lastName').invalid && registerForm.get('lastName').touched">Bitte geben
              Sie einen Nachnamen ein
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="form-field" appearance="outline">
          <mat-label>E-Mail Adresse:</mat-label>
          <input matInput type="email" formControlName="email">
          <mat-error *ngIf="registerForm.get('email').invalid && registerForm.get('email').touched">Bitte geben Sie eine
            gültige
            E-Mail Adresse ein
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Passwort:</mat-label>
          <input [attr.type]="passwordIsVisible ? 'text' : 'password'" matInput formControlName="password">
          <mat-error *ngIf="registerForm.get('password').hasError('minlength') && registerForm.get('password').touched">
            Das Passwort muss mind. 7 Zeichen lang sein
          </mat-error>
          <mat-icon (click)="togglePasswordVisibility()"
                    matSuffix>{{ passwordIsVisible ? 'visibility_off' : 'visibility' }}</mat-icon>
        </mat-form-field>

        <mat-checkbox color="primary" formControlName="newsletter">Newsletter erhalten</mat-checkbox>

      </form>

      <button class="register-button"
              [disabled]="registerForm.invalid"
              mat-raised-button color="primary" (click)="register()">
        Registrieren
      </button>
      <div class="data">
        Indem du fortfährst, erklärst du dich mit unseren
        <a>Nutzungsbedingungen</a>
        einverstanden und bestätigst, dass Du unsere
        <a>Datenschutzerklärung</a>
        gelesen hast

      </div>
    </div>

    <div class="action">
      Du hast bereits einen Account?
      <button (click)="backToLogin()" color="primary" mat-button>Hier anmelden</button>
    </div>
  </div>
</ng-container>

<!-- View after register - sent email -->
<ng-container *ngIf="sendEmailMessage">
  <div class="sent-mail-container">
    <h1>Bitte bestätigen Sie Ihre E-Mail-Adresse</h1>
    <img src="../../../../assets/img/email.png">
    <h1>Fast geschafft</h1>

    <div>{{this.sendEmailMessage}}</div>
    <div class="action">
      <button (click)="backToLogin()" color="primary" mat-button>Zurück zum Login</button>
    </div>
  </div>
</ng-container>


<app-loading-spinner *ngIf="loadingSpinner"></app-loading-spinner>
