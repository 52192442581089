<!--<mat-form-field class="search-driver">
  <mat-label>Fahrer suchen</mat-label>
  <input matInput (keyup)="searchDriver($event)" #input>
</mat-form-field>-->

<ng-container *ngIf="isLoading"></ng-container>
<table mat-table [dataSource]="tableData" matSort (matSortChange)="announceSortChange($event)">

  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ columnHeaders[column] }} </th>
    <td mat-cell *matCellDef="let element" (click)="openDriverDetails(element)">
      <ng-container *ngIf="column === 'current_workingtimestart' || column === 'current_workingtimeend'; else defaultCell">
        {{ this.datePipe.transform(element[column], 'dd/MM/yyyy') }}
      </ng-container>
      <ng-template #defaultCell>
        {{ element[column] }}
      </ng-template>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

</table>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
