
<div class="forget-container">

  <!-- forgot password form -->
  <ng-container *ngIf="sendEmail">
    <h1>Neues Passwort angefordert</h1>
    <img src="../../../../assets/img/email.png">
    <h1>Fast geschafft</h1>

    <div>Wir haben Ihnen soeben eine E-Mail an {{this.newPasswordForm.value.email}} gesendet. Klicken Sie auf den entsprechenden Link in der E-Mail.</div>


    <div class="action">
      <button (click)="cancel()" color="primary" mat-button>Zurück zum Login</button>
    </div>
  </ng-container>

  <!-- sent email information -->
  <ng-container *ngIf="!sendEmail">
    <div class="header">
      <img src="../../../../assets/img/logo-taxi-hess.png">
    </div>
    <div class="header">
      <h1>Passwort vergessen? Kein Problem</h1>
      <span>Bitte tragen Sie die E-Mail-Adresse ein, mit der Sie sich registriert haben.</span>
    </div>
    <mat-error class="login-error" *ngIf="loginError">
      <mat-icon class="exclamation-icon" inline="true">warning</mat-icon>
      Zu dieser E-Mail-Adresse gibt es keinen Account.
    </mat-error>

    <div class="formular">


      <form [formGroup]="newPasswordForm">
        <mat-form-field appearance="outline">
          <mat-label>E-Mail Adresse:</mat-label>
          <input matInput type="email" formControlName="email">
          <mat-error *ngIf="newPasswordForm.get('email').invalid && newPasswordForm.get('email').touched">Bitte geben
            Sie
            eine gültige
            E-Mail Adresse ein
          </mat-error>
        </mat-form-field>

      </form>

      <button class="new-password-button"
              [disabled]="newPasswordForm.invalid"
              mat-raised-button color="primary" (click)="newPassword()">
        Neues Passwort anfordern
      </button>
    </div>

    <div class="action">
      <button (click)="cancel()" color="primary" mat-button>Abbrechen</button>
    </div>
  </ng-container>
</div>


<app-loading-spinner *ngIf="loadingSpinner"></app-loading-spinner>
