import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {WebUserStore} from "../../../../stores/webUser.store";
import {WebusersService} from "../../../../api/webusers.service";
import {WebUser} from "../../../../interfaces/webuser";
import {MatDialog} from "@angular/material/dialog";
import {ChangePasswordComponent} from "../change-password/change-password.component";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  /** FormArray */
  profileFormArray: FormArray;
  basicInformation: FormGroup;
  contactDetails: FormGroup;
  address: FormGroup;
  password: FormGroup


  /** General */
  currentWebUser: WebUser;
  showProfile = true;
  headline = 'Benutzerprofil'
  formGroupNames = [
    {name: 'basicInformation', translation: 'Basis Informationen'},
    {name: 'contactDetails', translation: 'Kontakt Informationen'},
    {name: 'address', translation: 'Adresse'}]


  /** Edit Mode */
  actualFormNumber: number;
  actualForm: FormGroup;
  saveButtonIsDisabled = true;

  constructor(public dialog: MatDialog, private fb: FormBuilder, private webUserStore: WebUserStore, public datePipe: DatePipe, private webuserService: WebusersService) {
  }

  ngOnInit() {
    this.webUserStore.currentWebUser$.subscribe(currentWebUser => {
      if (currentWebUser) {
        this.currentWebUser = currentWebUser;
        this.profileFormArray = this.fb.array([
          this.basicInformation = this.fb.group({
            firstName: [this.currentWebUser.firstname, Validators.required],
            lastName: [this.currentWebUser.lastname, Validators.required],
            gender: [this.currentWebUser.gender],
            birthday: [this.currentWebUser.birthday]
          }),
          this.contactDetails = this.fb.group({
            email: [this.currentWebUser.email, Validators.required],
            phoneNumber: [this.currentWebUser.phoneNumber],
          }),
          this.address = this.fb.group({
            street: [this.currentWebUser.street],
            zipCode: [this.currentWebUser.zip],
            village: [this.currentWebUser.city],
          }),
        ])
      }
    });
  }

  getLabelForControl(control: any): string {
    const controlLabels = {
      firstName: 'Vorname',
      lastName: 'Nachname',
      gender: 'Geschlecht',
      birthday: 'Geburtstag',
      email: 'E-Mail',
      phoneNumber: 'Telefonnummer',
      street: 'Straße',
      zipCode: 'Postleitzahl',
      village: 'Stadt',
      password: 'Passwort',
    };

    return controlLabels[control];
  }

  getControlNames(formName: string): string[] {
    return Object.keys(this[formName].controls);
  }

  openEditMode(formName: string, form: number, actualForm): void {
    this.showProfile = false;
    this.actualFormNumber = form;
    this.actualForm = actualForm;
    this.headline = formName + ' ändern'
  }

  save(): void {
    this.webuserService.updateWebUserInformation(this.actualForm.value).subscribe(_ => {
    })
  }

  closeEditMode(): void {
    this.showProfile = true;
    this.headline = 'Benutzerprofil'
  }

  openPasswortChange(): void{
    const dialogRef = this.dialog.open(ChangePasswordComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
