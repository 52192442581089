<ng-container *ngIf="driver">
  <mat-card class="employee-card">
    <mat-card-header>
      <mat-card-title>{{ driver.firstname }} {{ driver.lastname }}</mat-card-title>
      <mat-card-subtitle>{{ driver.company }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

      <form [formGroup]="dateRangeForm">
        <mat-form-field appearance="fill">
          <mat-label>Auftragszeitraum</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Startdatum" formControlName="startDate">
            <input matEndDate placeholder="Enddatum" formControlName="endDate">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </form>

      <div class="info-block">
        <div class="title">
          <mat-icon>home</mat-icon>
          Adresse:
        </div>
        <div>{{ driver.street }}, {{ driver.zip }} {{ driver.city }}, {{ driver.state }}</div>
      </div>

      <mat-divider></mat-divider>

      <div class="info-block">
        <div class="title">
          <mat-icon>access_time</mat-icon>
          Gesamtarbeitszeit:
        </div>
        <div>{{ getTotalWorkTime() }} Stunden</div>
      </div>

      <mat-divider></mat-divider>

      <div class="info-block">
        <div class="title">
          <mat-icon>directions_car</mat-icon>
          Fahrzeit:
        </div>
        <div>{{ driver.drivingTimeInMinutes / 60 | number: '1.2-2' }} Stunden</div>
      </div>

      <mat-divider></mat-divider>

      <div class="info-block">
        <div class="title">
          <mat-icon>call</mat-icon>
          Rufbereitschaft:
        </div>
        <div>{{ driver.waitingTimeInMinutes / 60 | number: '1.2-2' }} Stunden</div>
      </div>

      <mat-divider></mat-divider>

      <div class="info-block">
        <div class="title">
          <mat-icon>free_breakfast</mat-icon>
          Pausenzeit:
        </div>
        <div>{{ driver.breakTimeInMinutes / 60 | number: '1.2-2' }} Stunden</div>
      </div>

      <mat-divider></mat-divider>

    </mat-card-content>
  </mat-card>
</ng-container>
