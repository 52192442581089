// `src/app/app-routing.module.ts`
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewDriverComponent } from './components/pages/overview-driver/overview-driver.component';
import { LoginComponent } from './core/auth/login/login.component';
import { AuthGuard } from './guard/auth_guard';
import { RegisterComponent } from './core/auth/register/register.component';
import { LoginGuard } from './guard/login_guard';
import { ForgetPasswordComponent } from './core/auth/forget-password/forget-password.component';
import { DriverDetailsComponent } from './components/pages/overview-driver/driver-details/driver-details.component';
import { ProfileRolesComponent } from './components/pages/settings/profile-roles/profile-roles.component';
import { ProfileComponent } from './components/pages/profile/profile/profile.component';
import { OverviewJobsComponent } from './components/pages/overview-jobs/overview-jobs.component';
import { OverviewVehicleComponent } from './components/pages/overview-vehicle/overview-vehicle.component';
import { WorktimeNotificationComponent } from './components/pages/worktime-notification/worktime-notification.component';
import { AccountingComponent } from './components/pages/accounting/accounting.component';
import { WorktimeComponent } from './components/pages/worktime/worktime.component';
import {AdminGuard} from "./guard/admin_guard";
import {
  WorktimeNotificationAdminComponent
} from "./components/pages/worktime-notification-admin/worktime-notification-admin.component";

const routes: Routes = [
  { path: '', component: OverviewDriverComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [LoginGuard] },
  { path: 'forget-password', component: ForgetPasswordComponent, canActivate: [LoginGuard] },
  { path: 'overview-driver', component: OverviewDriverComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'overview-jobs', component: OverviewJobsComponent, canActivate: [AuthGuard] },
  { path: 'overview-vehicle', component: OverviewVehicleComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'driver-details/:id', component: DriverDetailsComponent, canActivate: [AuthGuard] },
  { path: 'profile-roles', component: ProfileRolesComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'worktime-notification', component: WorktimeNotificationComponent, canActivate: [AuthGuard] },
  { path: 'accounting', component: AccountingComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'worktime', component: WorktimeComponent, canActivate: [AuthGuard] },
  { path: 'worktime-notification-admin', component: WorktimeNotificationAdminComponent, canActivate: [AuthGuard, AdminGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
