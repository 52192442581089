<form [formGroup]="dateRangeForm">
  <mat-form-field appearance="fill">
    <mat-label>Auftragszeitraum</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Startdatum" formControlName="startDate">
      <input matEndDate placeholder="Enddatum" formControlName="endDate">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</form>

    <table mat-table [dataSource]="tableData" class="mat-elevation-z8">
      <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">

        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="column === 'select'; else headerText">
            <mat-checkbox [checked]="masterCheckbox" (change)="changeMaster($event.checked)"></mat-checkbox>
          </ng-container>
          <ng-template #headerText>{{ columnsHeaders[column] }}</ng-template>
        </th>


        <td mat-cell *matCellDef="let driver">
          <ng-container *ngIf="column === 'select'; else defaultCell">
            <mat-checkbox [checked]="driver.select" (change)="changeCheckbox(driver, $event.checked)"></mat-checkbox>
          </ng-container>
          <ng-template #defaultCell>
            <ng-container *ngIf="column === 'drivingTime' || column === 'waitingTime' || column === 'breakTime'; then formattedCell else unformattedCell">
            </ng-container>
            <ng-template #formattedCell>{{ driver[column] | number:'1.2-2' }}</ng-template>
            <ng-template #unformattedCell>{{ driver[column] }}</ng-template>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <button mat-raised-button color="primary" (click)="exportSelectedData()">Exportieren</button>
<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
