import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {ProfileFrontendService} from '../services/profile-frontend.service';

@Injectable({
  providedIn: 'root'
})
export class WorktimeService {

  constructor(private httpClient: HttpClient, private profileFrontendService: ProfileFrontendService) {
  }

  getAllWorktimes(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/worktimes`, {
      headers: {token: this.profileFrontendService.getUserInformationFromStorage('token')}
    });
  }

  getAllWorktimeOfDriver(driverId: string, startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/worktimes/user`, {
      headers: { token: this.profileFrontendService.getUserInformationFromStorage('token') },
      params: { userId: driverId, startDate: startDate, endDate: endDate }
    });
  }
}
