import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {WebUser} from "../interfaces/webuser";

@Injectable({
  providedIn: 'root'
})
export class WebUserStore {
  private webUserSubject = new BehaviorSubject<WebUser | null>(null);
  currentWebUser$ = this.webUserSubject.asObservable();

  setWebUser(webUser: WebUser) {
    this.webUserSubject.next(webUser);
  }
}
