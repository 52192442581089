import { Injectable } from '@angular/core';
import {Accounting} from "../interfaces/accounting";

@Injectable({
  providedIn: 'root'
})
export class DriverService {
  constructor() { }


  exportPayrollData(data: Accounting[]): void {
    const csvData = this.convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'payroll-data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private convertToCSV(data: any[]): string {
    const array = [Object.keys(data[0])].concat(data);

    return array.map(it => {
      return Object.values(it).toString();
    }).join('\n');
  }
}
