// `src/app/guard/admin_guard.ts`
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { WebUserStore } from '../stores/webUser.store';
import { Observable } from 'rxjs';
import { map, first, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private webUserStore: WebUserStore, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.webUserStore.currentWebUser$.pipe(
      filter(webUser => webUser !== null && webUser !== undefined), // Filter out null or undefined values
      first(), // Wait for the first valid value emitted
      map(webUser => {
        if (webUser.roleOfUser === 'admin') {
          return true;
        } else {
          this.router.navigate(['/overview-jobs']);
          return false;
        }
      })
    );
  }
}
