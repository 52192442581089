import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {LiveAnnouncer} from "@angular/cdk/a11y";

import Dummy from '../overview-vehicle/example-data.json';
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {Vehicle} from "../../../interfaces/vehicle";
import {VEHICLE_TABLE_COLUMNS} from "../../../config/table-configurations";
import {TranslationService} from "../../../services/translate.service";


@Component({
  selector: 'app-overview-vehicle',
  templateUrl: './overview-vehicle.component.html',
  styleUrls: ['./overview-vehicle.component.scss']
})
export class OverviewVehicleComponent implements OnInit, AfterViewInit {
  vehicleData: Vehicle[] = Dummy.data;

  dataSource = new MatTableDataSource(this.vehicleData);

  displayedColumns: string[] = VEHICLE_TABLE_COLUMNS;
  columnHeaders = this.translationService.translateStrings(this.displayedColumns);


  constructor(private translationService: TranslationService, private _liveAnnouncer: LiveAnnouncer, private router: Router, public datePipe: DatePipe) {
  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction === 'asc') {
      this._liveAnnouncer.announce(`Sorted ascending`);
    } else if (sortState.direction === 'desc') {
      this._liveAnnouncer.announce(`Sorted descending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  searchDriver(event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDriverDetails(driverId: string): void{
    this.router.navigate(['/driver-details', driverId])
  }

}
