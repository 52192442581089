import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const localIsLoggedIn = localStorage.getItem('isLoggedIn');
    const sessionIsLoggedIn = sessionStorage.getItem('isLoggedIn');

    if (localIsLoggedIn === 'true' || sessionIsLoggedIn === 'true') {
      return this.router.parseUrl('/overview-jobs');
    } else if (localIsLoggedIn === 'false' || sessionIsLoggedIn === 'false') {
      return true
    } else {
      sessionStorage.setItem('isLoggedIn', 'false');
      return true;
    }


    /*if (localStorage.getItem('isLoggedIn') === null && sessionStorage.getItem('isLoggedIn') === null) {
      localStorage.setItem('isLoggedIn', 'false');
    } else if ((localStorage.getItem('isLoggedIn') === 'false' || sessionStorage.getItem('isLoggedIn') === 'false')) {
      return true;
    }

    return this.router.parseUrl('/overview-driver');*/
  }
}

